<template>
    <div>
        <statistics-bar-chart-template scale="daily" type="newDevices">
            <template #title>{{ $t('newDevices.dnd') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="weekly" type="newDevices">
            <template #title>{{ $t('newDevices.wnd') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="monthly" type="newDevices">
            <template #title>{{ $t('newDevices.mnd') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="yearly" type="newDevices">
            <template #title>{{ $t('newDevices.ynd') }}</template>
        </statistics-bar-chart-template>
    </div>
</template>

<script>
import StatisticsBarChartTemplate from '../statistics-components/statistics-dynamic/StatisticsBarChartTemplate.vue';

export default {
    components: { StatisticsBarChartTemplate },
};
</script>

<style lang="scss" scoped></style>
